<template>
  <Title pageTitle="MEMBERS" pageDiscrption="会員サービス" />
  <div class="container">
    <div class="container-narrow">
      <p>当店の会員サービスにご登録いただいたお客様はお得なポイントサービスをご利用いただけます。</p>
      <p>ご購入の度にお客様情報を入力する手間が省けるのはもちろん、商品購入時に次回のお買い物にご利用いただけるお得なポイントを付与させていただきます。</p>
      <p>またお得なキャンペーン情報や新商品の紹介、Tシャツにまつわる情報などを不定期でお送りしております。</p>
      <p>ポイントサービスの詳細は下記をご覧ください。</p>
      <div class="point">
        <div class="point__title">ポイント還元率</div>
        <div class="point__description">商品購入金額100円につき1ポイントを付与いたします</div>
        <div class="point__arrow"><span class="material-icons">arrow_downward</span></div>
        <div class="point__description">1ポイントを1円換算にてお買い物時にご利用いただけます</div>
        <div class="point__caption">
          <div class="cap">10円単位は切り捨てとなります（例：16,848円 → 168ポイント）</div>
          <div class="cap">1度のお買い物でご利用できるポイントは100ポイントから5000ポイントまでとさせていただきます。</div>
          <div class="cap">ポイントのご利用はお買い上げ商品の合計金額が1,000円以上で、利用ポイントは10ポイント単位とさせていただきます。</div>
        </div>
      </div>
      <div class="btn">
        <a href="https://cart.ec-sites.jp/sp_step2/new-member/input/es_shop_id/4777/?_ga=2.92926493.1875182649.1576845152-1853287019.1576586744">新規会員登録</a>
        <a href="https://cart.ec-sites.jp/sp_step2/mypage/top/shid/4777/?_ga=2.92926493.1875182649.1576845152-1853287019.1576586744">ログイン</a>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../components/Title.vue'
export default {
  components: {
    Title,
  },
}
</script>

<style lang="scss" scoped>
.point {
  margin-top: 6rem;
  text-align: center;

  &__title {
    font-size: 13px;
    padding: 5px 20px 8px;
    background-color: #000;
    border-radius: 100px;
    display: inline-block;
    margin-bottom: 3rem;
  }

  &__description {
    background-color: var(--color-dark);
    font-weight: 300;
    padding: 10px;
  }

  &__arrow {
    margin: 1rem 0;
  }

  &__caption {
    text-align: left;
    margin-top: 2rem;
  }
}
.btn {
  margin: 5rem 0 3rem;
  display: flex;
  justify-content: center;

  & a {
    width: 300px;
    margin: 0 10px;
    text-align: center;
    display: inline-block;
    color: var(--color-light);
    padding: 15px 20px 17px;
    border: 1px solid #555;
    border-radius: 100px;
    font-size: 1.5rem;
    font-weight: 300;
    cursor: pointer;
    transition: border 0.3s ease-out;
  }

  & a:hover {
    border: 1px solid #aaa;
  }
}
</style>
